











import { Component, Vue, Prop } from 'vue-property-decorator'

import FileUploadWarning from '@/components/modals/FileUploadWarning.vue'

@Component({
  components: {
    FileUploadWarning
  },
})
export default class FileUploadButton extends Vue {

  @Prop()
  private multiple!: boolean

  @Prop()
  private accept!: string

  private confirmModalOpen: boolean = false

  private addFiles(event: Event): void {
    const target = event.target as HTMLInputElement
    if (target) {
      const files = target.files
      this.$emit('change', files)
    }
  }

  private beforeUpload(): void {
    this.confirmModalOpen = true
  }

  private closeModal(): void {
    this.confirmModalOpen = false
  }

  private openUpload(): void {
    this.closeModal()
    const fileUploadInput = this.$refs.fileinput as HTMLElement
    this.$nextTick(() => {
      if (!fileUploadInput) return
      fileUploadInput.click()
    })
  }
}
