















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class File extends Vue {

  @Prop()
  private file!: { name: string, url?: string }

  @Prop({ default: false })
  private canRemove!: boolean

}
