



















import Cookies from 'js-cookie'
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

import DefaultModal from './Default.vue'
import CheckboxInput from './../inputs/Checkbox.vue'

const COOKIE_KEY = 'upload_warning'

@Component({
  components: {
    DefaultModal,
    CheckboxInput
  }
})
export default class FileUploadWarning extends Vue {

  @Prop()
  private open!: boolean

  private modalOpen: boolean = false

  private remember: boolean = false

  @Watch('open')
  private onOpenChange(val: boolean): void {
    this.remember = false
    const rememberCookie = Cookies.get(COOKIE_KEY)
    if (rememberCookie !== 'true') {
      this.modalOpen = val
    } else {
      this.$nextTick(() => {
        this.submit()
      })
      this.modalOpen = false
    }
  }

  private submit() {
    if (this.remember) {
      Cookies.set(COOKIE_KEY, this.remember.toString())
    }
    this.$emit('submit')
  }

  private cancel() {
    this.$emit('cancel')
  }

}
